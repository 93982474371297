import React, { useState } from "react";
import Body from "Components/Atoms/Text/body";
import useInput from "Util/Hooks/useInput";
import Button from "Components/Atoms/Button/button";
import InputWithLabel from "Components/Molecules/InputWithLabel/inputWithLabel";
import LoginTemplate from "./settingTemplate";
import { putPwApi } from "Apis/apis";
import { logout } from "Store/Slices/user";
import { useNavigate } from "react-router-dom";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import HeadLine from "Components/Atoms/Text/headLine";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as Search } from "Util/Svgs/search.svg";

const Post = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { setValue: setId, ...restId } = useInput("");
  const { setValue: setPw, ...restPw } = useInput("");
  const { setValue: setRePw, ...restRePw } = useInput("");

  const [msg, setMsg] = useState("");

  const login: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const account = {
      email: restId.value,
      newPassword: restPw.value,
      confirmNewPassword: restRePw.value,
    };
    try {
      const { data } = await putPwApi(account);
      if (data.code === "SUCCESS") {
        alert("변경 완료! 다시 로그인 해주세요.");
        dispatch(logout());
        navigate("/");
      }
    } catch (error) {
      const err: any = error;
      setMsg(err.response.data.message);
    }
  };

  return (
    <div>
      <Flex x="justify-between">
        <HeadLine text="포스트관리" extraBold />
        <button onClick={() => dispatch(modalToggle(100))}>
          <Search />
        </button>
      </Flex>
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <LoginTemplate
          Title={<HeadLine extraBold text="어드민 계정 변경" />}
          Desc={
            <Body
              extraBold
              text="현재 로그인한 어드민 계정의 정보를 변경합니다"
            />
          }
          Id={
            <InputWithLabel
              text="이메일"
              type="email"
              placeholder="clpick@gmail.com"
              {...restId}
            />
          }
          Pw={
            <InputWithLabel
              text="비밀번호"
              type="password"
              placeholder="******"
              {...restPw}
            />
          }
          RePw={
            <InputWithLabel
              text="비밀번호 확인"
              type="password"
              placeholder="******"
              {...restRePw}
            />
          }
          Msg={
            <p className="absolute left-0 top-380 text-warning text-18 semiBold">
              {msg}
            </p>
          }
          Btn={
            <div className="text-center px-50">
              <Button type="submit" text="로그인" />
            </div>
          }
          login={login}
        />
      </div>
    </div>
  );
};

export default Post;
