import { AlignItems, JustifyContent } from "types/interface";

interface FlexProps {
  x?: JustifyContent;
  y?: AlignItems;
  vertical?: boolean;
  children: React.ReactNode;
  full?: boolean;
  px?: number;
  py?: number;
  mx?: number;
  my?: number;
}

const Flex = ({
  x = "justify-start",
  y = "items-center",
  vertical = false,
  children,
  full = true,
  px = 0,
  py = 0,
  mx = 0,
  my = 0,
}: FlexProps) => {
  return (
    <div
      className={`flex ${full && "w-full"} ${
        vertical && "flex-col"
      }  ${x} ${y}`}
      style={{
        paddingLeft: `${px}px`,
        paddingRight: `${px}px`,
        paddingTop: `${py}px`,
        paddingBottom: `${py}px`,
        marginLeft: `${mx}px`,
        marginRight: `${mx}px`,
        marginTop: `${my}px`,
        marginBottom: `${my}px`,
      }}
    >
      {children}
    </div>
  );
};

export default Flex;
