import axios, { AxiosResponse } from "axios";
import { accessToken, reAccessToken } from "Util/function";

const v1 = process.env.REACT_APP_API_V1;

// 에러status

// 변수로 작성시 getToken함수를 매번 실행하지 않음(최초 진입시 빈값)

const instance = () => {
  return axios.create({
    baseURL: v1,
    headers: { Authorization: `Bearer ${accessToken.get()}` },
  });
};

const errorHandleApi = async (
  api: () => Promise<AxiosResponse<any, any>>
): Promise<any> => {
  return await api().catch(async (err: any) => {
    // 토큰 만료시
    if (err.response.data.message === "EXPIRED_ACCESS_TOKEN") {
      await reAccessToken();
      return api();
    }
    throw err;
  });
};

// get api
export const getApi = async (url: string) =>
  errorHandleApi(() => instance().get(url));

// post api
export const postApi = async (url: string, body?: any) =>
  errorHandleApi(() => instance().post(url, body));

// put api
export const putApi = async (url: string, body?: any) =>
  errorHandleApi(() => instance().put(url, body));

// delete api
export const deleteApi = async (url: string) =>
  errorHandleApi(() => instance().delete(url));

// body있는 delete api
export const deleteBody = async (url: string, body: any) => {
  const data = await axios({
    method: "DELETE",
    url: `${v1}${url}`,
    data: body,
    headers: { Authorization: `Bearer ${accessToken.get()}` },
  });
  return data;
};
