import { postsApi } from "Apis/apis";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { activePostToggle, comma, dateFormat, getIdx } from "Util/function";
import { Table, Switch } from "antd";
import { ReactComponent as Search } from "Util/Svgs/search.svg";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { PostOneT, PostT } from "types/interface";
import SearchModal from "Components/Molecules/Modal/searchModal";
import { useLocation } from "react-router-dom";
import PostModal from "Components/Molecules/Modal/postModal";

const DATA = [
  { key: "index", val: "index", type: "text" },
  { key: "identifier", val: "ID", type: "text" },
  { key: "nickname", val: "닉네임", type: "text" },
  { key: "postTitle", val: "포스트 제목", type: "text" },
  { key: "topicTitle", val: "토픽 제목", type: "text" },
];

const Post = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [postList, setPostList] = useState<PostT[]>([]);
  const [max, setMax] = useState(0);
  const [seletedPost, setSeletedPost] = useState({} as PostOneT);

  const getTopicList = async () => {
    const { key = "", val = "" } = location.state || {};

    const {
      data: { data },
    } = await postsApi.get(`${key}=${val}`);

    setMax(data.postsCount);
    setPostList(data.posts);
  };

  const getOnePost = async (idx: number) => {
    const { data } = await postsApi.getOne(idx);
    setSeletedPost(data.data.post);
  };

  const onSuccess = (topicId: number) => {
    getTopicList();
    getOnePost(topicId);
  };

  const columns: TableProps<PostT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["ascend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(postList, a, "index"),
    },
    {
      title: "ID",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
    },
    {
      title: "닉네임",
      dataIndex: "nickname",
      width: 100,
      key: "nickname",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
    },
    {
      title: "썸네일",
      dataIndex: "thumbnail",
      width: 100,
      key: "thumbnail",
      render: (thumb) => <img src={thumb.decorateFileURL} alt="" />,
    },
    {
      title: "포스트제목",
      dataIndex: "postTitle",
      key: "postTitle",
      sorter: (a, b) => a.postTitle.localeCompare(b.postTitle),
    },
    {
      title: "토픽제목",
      dataIndex: "topicTitle",
      key: "topicTitle",
      sorter: (a, b) => a.topicTitle.localeCompare(b.topicTitle),
    },
    {
      title: "리스트제목",
      dataIndex: "listTitle",
      key: "listTitle",
      sorter: (a, b) => a.listTitle.localeCompare(b.listTitle),
    },
    {
      title: "댓글",
      dataIndex: "commentsCount",
      key: "commentsCount",
      width: 100,
      sorter: (a, b) => a.commentsCount - b.commentsCount,

      render: (reward) => comma(reward),
    },
    {
      title: "좋아요",
      dataIndex: "likesCount",
      key: "likesCount",
      width: 100,
      sorter: (a, b) => a.likesCount - b.likesCount,

      render: (reward) => comma(reward),
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 135,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => dateFormat(date),
    },
    {
      title: "삭제일자",
      dataIndex: "deletedAt",
      key: "deletedAt",
      width: 135,
      sorter: (a, b) => (a.deletedAt || "").localeCompare(b.deletedAt || ""),
      render: (date) => dateFormat(date) || "",
    },
    {
      title: "숨김",
      dataIndex: "isHidden",
      key: "isHidden",
      width: 100,
      sorter: (a, b) => {
        return a.isHidden.toString().localeCompare(b.isHidden.toString());
      },
      render: (hidden, topic) => (
        <Switch
          onChange={(e) => {
            activePostToggle(topic.index, onSuccess);
          }}
          checked={hidden}
        />
      ),
    },
  ];

  useEffect(() => {
    getTopicList();
  }, [location]);

  return (
    <div>
      <Flex x="justify-between">
        <HeadLine text="포스트관리" extraBold />
        <button onClick={() => dispatch(modalToggle(100))}>
          <Search />
        </button>
      </Flex>
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <Title text={`${max}개의 포스트`} />
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={postList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                const target: any = e.target;
                getOnePost(record.index);
                const isSwitch = target.classList.value.includes("switch"); // 스위치 클릭 여부
                !isSwitch && dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      <PostModal
        row={seletedPost || ({} as PostOneT)}
        activeToggle={() => activePostToggle(seletedPost.index, onSuccess)}
        modalNum={1}
      />
      <SearchModal data={DATA} />
    </div>
  );
};

export default Post;
