import { goodsApi } from "Apis/apis";
import Button from "Components/Atoms/Button/button";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import { getIdx } from "Util/function";
import { Table } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { GoodsT } from "types/interface";
import { ReactComponent as Pencil } from "Util/Svgs/pencil.svg";
import { ReactComponent as Search } from "Util/Svgs/search.svg";
import { ReactComponent as PencilActive } from "Util/Svgs/pencil_active.svg";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import Caption from "Components/Atoms/Text/caption";
import { useAppDispatch } from "Store/store";
import { modal, modalToggle } from "Store/Slices/modal";
import NoticeModal from "./noticeModal";
import SearchModal from "Components/Molecules/Modal/searchModal";
import { useLocation } from "react-router-dom";

type TableRowSelection<T> = TableProps<T>["rowSelection"];

interface ListModalProps {
  row: GoodsT;
}

const DATA = [
  { key: "index", val: "index", type: "text" },
  { key: "productName", val: "기프티콘 이름", type: "text" },
  { key: "productId", val: "기프티콘 번호", type: "text" },
  { key: "brandName", val: "브랜드", type: "text" },
];
const GoodsModal = ({ row }: ListModalProps) => {
  return (
    <Detailmodal title={`포인트 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="기프티콘 번호" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.productId} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="브랜드" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.brandName} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="기프티콘 이름" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.productName} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="카테고리" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.category} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="유의사항" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.notice || ""} />
        </div>
      </Flex>
    </Detailmodal>
  );
};

const Goods = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [goodsList, setGoodsList] = useState<GoodsT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const actionToggle = () => {
    dispatch(modalToggle(2));
  };

  const rowSelection: TableRowSelection<GoodsT> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getGoodsList = async () => {
    const { key = "", val = "" } = location.state || {};
    const {
      data: { data },
    } = await goodsApi.get(`${key}=${val}`);

    setMax(data.productsCount);
    setGoodsList(data.products);
  };

  const delGoods = async () => {
    const confirm = window.confirm("삭제 하시겠습니까?");
    if (!confirm) return;
    const { data } = await goodsApi.delete(selectedRowKeys);
    if (data.code === "SUCCESS") {
      getGoodsList();
    }
  };

  const postAsyncGoods = async () => {
    const confirm = window.confirm("동기화 하시겠습니까?");
    if (!confirm) return;
    const { data } = await goodsApi.postAsyncGoods();
    if (data.code === "SUCCESS") {
      alert("동기화성공!");
      getGoodsList();
    }
  };

  const columns: TableProps<GoodsT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["ascend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(goodsList, a, "index"),
    },
    {
      title: "기프티콘 번호",
      dataIndex: "productId",
      key: "productId",
      sorter: (a, b) => a.productId.localeCompare(b.productId),
    },
    {
      title: "브랜드",
      dataIndex: "brandName",
      key: "brandName",
      sorter: (a, b) => a.brandName.localeCompare(b.brandName),
    },
    {
      title: "기프티콘 이름",
      dataIndex: "productName",
      key: "productName",
      sorter: (a, b) => a.productName.localeCompare(b.productName),
    },
    {
      title: "카테고리",
      dataIndex: "category",
      key: "category",
      filters: [
        {
          text: "커피/음료",
          value: "커피/음료",
        },
        {
          text: "아이스크림",
          value: "아이스크림",
        },
        {
          text: "베이커리/떡",
          value: "베이커리/떡",
        },
        {
          text: "편의점/마트",
          value: "편의점/마트",
        },
        {
          text: "문화/생활/기타",
          value: "문화/생활/기타",
        },
        {
          text: "피자/버거/치킨",
          value: "피자/버거/치킨",
        },
      ],
      onFilter: (value: any, record) => record.category.indexOf(value) === 0,
    },
    {
      title: "유의사항",
      dataIndex: "notice",
      key: "notice",
      width: 100,
      render: (notice, report) => (
        <button onClick={() => actionToggle()}>
          {notice ? <PencilActive /> : <Pencil />}
        </button>
      ),
    },
  ];

  useEffect(() => {
    getGoodsList();
  }, [location]);

  return (
    <div>
      <Flex x="justify-between">
        <HeadLine text="별샵상품 관리" extraBold />
        <button onClick={() => dispatch(modalToggle(100))}>
          <Search />
        </button>
      </Flex>
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <Flex>
          <Title text={`${max}개의 항목`} />
          <Space x={23} />
          <Button
            color="bg-attention"
            onClick={delGoods}
            text="선택한 기프티콘 삭제"
            full={false}
          />
          <Space x={23} />
          <Button
            color="bg-primary"
            onClick={postAsyncGoods}
            text="별샵 상품 동기화"
            full={false}
          />
        </Flex>
        <Space y={23} />
        <Table
          rowKey="index"
          rowSelection={rowSelection}
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={goodsList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                const target: any = e.target;
                const value: any = target.tagName;
                setSelectedRowIdx(record.index);
                const isAction =
                  value.includes("rect") || value.includes("path"); // 유의사항 아이콘 클릭 여부
                !isAction && dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <>
          <GoodsModal
            row={
              goodsList.find((el) => el.index === selectedRowIdx) ||
              ({} as GoodsT)
            }
          />
          <NoticeModal
            row={
              goodsList.find((el) => el.index === selectedRowIdx) ||
              ({} as GoodsT)
            }
            getGoods={getGoodsList}
          />
        </>
      )}
      <SearchModal data={DATA} />
    </div>
  );
};

export default Goods;
