import { useDispatch, useSelector } from "react-redux";
import { ReducerType, useAppDispatch } from "Store/store";
import { modalToggle } from "Store/Slices/modal";
import HeadLine from "Components/Atoms/Text/headLine";
import Flex from "Components/Atoms/Flex/flex";
import { ReactComponent as X } from "Util/Svgs/x.svg";
import Space from "Components/Atoms/Space/space";

/**
 * 전체화면 백그라운드
 * @param  modal 모달넘버
 * @param  child 안에 보일 엘리먼트
 * @returns
 */
const DetailModal = ({
  children,
  modalNum,
  title,
}: {
  children: React.ReactNode;
  modalNum: number;
  title: string;
}) => {
  const open = useSelector((state: ReducerType) => state.modal);
  const dispatch = useAppDispatch();

  if (modalNum !== open) return null;
  return (
    <div
      onClick={() => {
        dispatch(modalToggle(0));
      }}
      className="fixed w-full h-[100vh] bg-black1/2 top-0 left-0 z-50"
    >
      {/* 버블링 막음 */}
      <div
        onClick={(e) => e.stopPropagation()}
        className="fixed p-center bg-white py-50 rounded-3xl w-[936px]"
      >
        <Flex x="justify-between" px={50}>
          <HeadLine extraBold text={title} />
          <button
            onClick={() => {
              dispatch(modalToggle(0));
            }}
          >
            <X />
          </button>
        </Flex>
        <Space y={24} />
        <div className="px-50 max-h-[80vh] overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default DetailModal;
