import React, { useState, useEffect } from "react";

import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Plainmodal from "Components/Molecules/Modal/plainmodal";

interface modifyModalTemplateProps {
  Title: JSX.Element;
  Id: JSX.Element;
  AutoCom: JSX.Element;
  Content: JSX.Element;
  Point: JSX.Element;
  Msg: JSX.Element;
  Btn: JSX.Element;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

const ModifyModalTemplate = ({
  Title,
  Id,
  AutoCom,
  Content,
  Point,
  Msg,
  Btn,
  onSubmit,
}: modifyModalTemplateProps) => {
  return (
    <Plainmodal modalNum={2}>
      <Flex full vertical>
        {Title}
      </Flex>
      <Space y={25} />
      <form onSubmit={onSubmit} className="relative">
        {Id}
        <div className="relative">{AutoCom}</div>
        <Space y={40} />
        {Content}
        <Space y={40} />
        {Point}
        <Space y={16} />
        {Msg}
        <Space y={64} />
        {Btn}
      </form>
    </Plainmodal>
  );
};

export default ModifyModalTemplate;
