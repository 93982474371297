import React from "react";
import Caption from "Components/Atoms/Text/caption";
import Input from "Components/Atoms/Input/input";
import Space from "Components/Atoms/Space/space";

interface InputWithLabelProps extends React.ComponentProps<"input"> {
  text: string;
}

const InputWithLabel = ({ text, ...rest }: InputWithLabelProps) => {
  return (
    <>
      <Caption extraBold text={text} />
      <Space y={15} />
      <Input {...rest} full />
    </>
  );
};

export default InputWithLabel;
