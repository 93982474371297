import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = 0;

export const modal = createSlice({
  name: "modal",
  initialState,
  reducers: {
    modalToggle: (state: number, action: PayloadAction<number>) => {
      return state === action.payload ? 0 : action.payload;
    },
  },
});

export const { modalToggle } = modal.actions;
export default modal.reducer;
