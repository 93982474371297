import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Caption from "Components/Atoms/Text/caption";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import { dateFormat } from "Util/function";
import { Switch } from "antd";
import { PostOneT } from "types/interface";
interface PostModalProps {
  row: PostOneT;
  modalNum: number;
  activeToggle: () => Promise<void>;
}

const PostModal = ({ row, modalNum, activeToggle }: PostModalProps) => {
  return (
    <Detailmodal title={`포스트 #${row.index}`} modalNum={modalNum}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="ID" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.identifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="닉네임" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.nickname} />
        </div>
      </Flex>
      <Flex py={12} vertical y="items-start">
        <div className="w-180">
          <Caption text="썸네일" />
        </div>
        <Space y={12} />
        <div className="flex flex-1 gap-8 overflow-auto">
          {row?.postContents?.map((el) => (
            <img
              key={el.id}
              src={el.postContentElement.decorateFileURL}
              alt="afds"
            />
          ))}
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="포스트제목" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.postTitle} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="토픽제목" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.topicTitle} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="리스트제목" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.listTitle} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="댓글" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.commentsCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="좋아요" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.likesCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="생성일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="삭제일자" />
        </div>
        <div className="flex-1">
          <Caption
            color="text-headline"
            text={row.deletedAt ? dateFormat(row.deletedAt) : "-"}
          />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="숨김" />
        </div>
        <Switch onChange={activeToggle} checked={row.isHidden} />
      </Flex>
    </Detailmodal>
  );
};

export default PostModal;
