import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "types/interface";
import { accessToken, refreshToken } from "Util/function";

const initialState: User = {
  idx: -1,
  email: "",
};
const logoutState: User = {
  idx: 0,
  email: "",
};

export const users = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    logout: () => {
      accessToken.del();
      refreshToken.del();
      return logoutState;
    },
  },
});

export const { setUser, logout } = users.actions;
export default users.reducer;
