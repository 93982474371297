/**
 * 공백 atomic
 * @param  child 안에 보일 엘리먼트
 * @returns
 */
const Space = ({ x = 1, y = 1 }: { x?: number; y?: number }) => {
  return <div style={{ width: `${x}px`, height: `${y}px` }}></div>;
};

export default Space;
