import { commentApi } from "Apis/apis";
import Space from "Components/Atoms/Space/space";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import CommnetModal from "Components/Molecules/Modal/commentModal";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { activeCommentToggle, dateFormat, getIdx } from "Util/function";
import { Table, Switch } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { CommentT } from "types/interface";

const Comment = () => {
  const dispatch = useAppDispatch();

  const [commentList, setCommentList] = useState<CommentT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const getCommentList = async () => {
    const {
      data: { data },
    } = await commentApi.get();

    setMax(data.commentsCount);
    setCommentList(data.comments);
  };

  const columns: TableProps<CommentT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["ascend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(commentList, a, "index"),
    },
    {
      title: "ID",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
    },
    {
      title: "닉네임",
      dataIndex: "nickname",
      width: 100,
      key: "nickname",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
    },
    {
      title: "유형",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (type) => (type === "comment" ? "댓글" : "답글"),
    },
    {
      title: "댓글내용",
      dataIndex: "content",
      key: "content",
      sorter: (a, b) => a.content.localeCompare(b.content),
    },
    {
      title: "포스트제목",
      dataIndex: "postTitle",
      key: "postTitle",
      sorter: (a, b) => a.postTitle.localeCompare(b.postTitle),
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 135,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => dateFormat(date),
    },
    {
      title: "삭제일자",
      dataIndex: "deletedAt",
      key: "deletedAt",
      width: 135,
      sorter: (a, b) => (a.deletedAt || "").localeCompare(b.deletedAt || ""),
      render: (date) => dateFormat(date) || "",
    },
    {
      title: "숨김",
      dataIndex: "isHidden",
      key: "isHidden",
      width: 100,
      sorter: (a, b) => {
        return a.isHidden.toString().localeCompare(b.isHidden.toString());
      },
      render: (hidden, comment) => (
        <Switch
          onChange={() =>
            activeCommentToggle(comment.index, comment.type, () =>
              getCommentList()
            )
          }
          checked={hidden}
        />
      ),
    },
  ];

  useEffect(() => {
    getCommentList();
  }, []);

  return (
    <div>
      <HeadLine text="댓글관리" extraBold />
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <Title text={`${max}개의 댓글`} />
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={commentList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                const target: any = e.target;
                setSelectedRowIdx(record.index);

                const isSwitch = target.classList.value.includes("switch"); // 스위치 클릭 여부
                !isSwitch && dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      <CommnetModal
        row={
          commentList.find((el) => el.index === selectedRowIdx) ||
          ({} as CommentT)
        }
        activeToggle={() =>
          activeCommentToggle(
            selectedRowIdx,
            (
              commentList.find((el) => el.index === selectedRowIdx) ||
              ({} as CommentT)
            ).type,
            () => getCommentList()
          )
        }
        modalNum={1}
      />
    </div>
  );
};

export default Comment;
