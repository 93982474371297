import Flex from "Components/Atoms/Flex/flex";
import Caption from "Components/Atoms/Text/caption";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import { dateFormat } from "Util/function";
import { Switch } from "antd";
import { CommentT, CommentType } from "types/interface";
interface CommentModalProps {
  row: CommentT;
  activeToggle: (idx: number, type: CommentType) => {};
  modalNum: number;
}

const CommnetModal = ({ row, activeToggle, modalNum }: CommentModalProps) => {
  return (
    <Detailmodal title={`댓글 #${row.index}`} modalNum={modalNum}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="ID" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.identifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="닉네임" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.nickname} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="유형" />
        </div>
        <div className="flex-1">
          <Caption
            color="text-headline"
            text={row.type === "comment" ? "댓글" : "답글"}
          />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="댓글내용" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.content} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="포스트제목" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.postTitle} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="생성일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="삭제일자" />
        </div>
        <div className="flex-1">
          <Caption
            color="text-headline"
            text={row.deletedAt ? dateFormat(row.deletedAt) : "-"}
          />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="숨김" />
        </div>
        <Switch
          onChange={() => {
            activeToggle(row.index, row.type);
          }}
          checked={row.isHidden}
        />
      </Flex>
    </Detailmodal>
  );
};

export default CommnetModal;
