/**
 * 사이드바 섹션
 * @param menu 섹션 데이터
 * @param nowPath 현재 url정보
 */

import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Body from "Components/Atoms/Text/body";
import Caption from "Components/Atoms/Text/caption";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const SIDE_BTN_LIST_1 = [
  { text: "토픽관리", path: "topic" },
  { text: "포스트관리", path: "post" },
  { text: "댓글관리", path: "comment" },
  { text: "계정관리", path: "account" },
  { text: "리스트관리", path: "list" },
  { text: "신고리포트", path: "report" },
];
const SIDE_BTN_LIST_2 = [
  { text: "포인트관리", path: "point" },
  { text: "별샵상품관리", path: "goods" },
];

const Sidebar = () => {
  const location = useLocation();

  return (
    <nav
      id="sidebar"
      className="pretty-scrollbar py-24 w-240 h-[100vh] flex flex-col overflow-y-auto border-r-1 border-borderLine"
    >
      <Flex px={24}>
        <Body text="Clpick" color="text-primary" />
        <Space x={5} />
        <Body text="admin" color="text-caption" />
      </Flex>
      <Space y={16} />

      <Flex vertical>
        {SIDE_BTN_LIST_1.map((siedBtn) => (
          <Link
            key={siedBtn.path}
            to={siedBtn.path}
            className={`flex items-center justify-center h-56 text-center rounded-md w-192 ${
              location.pathname.includes(siedBtn.path)
                ? "bg-primary"
                : "bg-white"
            }`}
          >
            <Caption
              text={siedBtn.text}
              color={`${
                location.pathname.includes(siedBtn.path)
                  ? "text-white"
                  : "text-body"
              }`}
            />
          </Link>
        ))}
      </Flex>
      <div className="my-16 border-y-1 border-borderLine ">
        <Flex vertical py={16}>
          {SIDE_BTN_LIST_2.map((siedBtn) => (
            <Link
              key={siedBtn.path}
              to={siedBtn.path}
              className={`flex items-center justify-center h-56 text-center rounded-md w-192 ${
                location.pathname.includes(siedBtn.path)
                  ? "bg-primary"
                  : "bg-white"
              }`}
            >
              <Caption
                text={siedBtn.text}
                color={`${
                  location.pathname.includes(siedBtn.path)
                    ? "text-white"
                    : "text-body"
                }`}
              />
            </Link>
          ))}
        </Flex>
      </div>
      <Flex vertical>
        <Flex px={24}>
          <Caption text="설정" color="text-body" extraBold />
        </Flex>
        <Space y={16} />
        <Link
          to="setting"
          className={`flex items-center justify-center h-56 text-center rounded-md w-192 ${
            location.pathname.includes("setting") ? "bg-primary" : "bg-white"
          }`}
        >
          <Caption
            text="어드민 설정"
            color={`${
              location.pathname.includes("setting") ? "text-white" : "text-body"
            }`}
          />
        </Link>
      </Flex>
    </nav>
  );
};

export default Sidebar;
