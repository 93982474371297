import { postsApi, reportApi, commentApi } from "Apis/apis";
import Space from "Components/Atoms/Space/space";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import {
  activeCommentToggle,
  activePostToggle,
  dateFormat,
  getIdx,
} from "Util/function";
import { Table, Tag } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { CommentT, PostOneT, ReportT, ReportTypeT } from "types/interface";
import { ReactComponent as CheckActive } from "Util/Svgs/check_active.svg";
import { ReactComponent as Search } from "Util/Svgs/search.svg";
import { ReactComponent as Check } from "Util/Svgs/check.svg";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import Flex from "Components/Atoms/Flex/flex";
import Caption from "Components/Atoms/Text/caption";
import { useAppDispatch } from "Store/store";
import { modalToggle } from "Store/Slices/modal";
import { useLocation } from "react-router-dom";
import SearchModal from "Components/Molecules/Modal/searchModal";
import PostModal from "Components/Molecules/Modal/postModal";
import CommnetModal from "Components/Molecules/Modal/commentModal";

interface ReportModalProps {
  row: ReportT;
  actionToggle: (idx: number, type: ReportTypeT) => {};
}
const REPORT_TYPE: { [idx: string]: { text: string; color: string } } = {
  post: { text: "포스트", color: "magenta" },
  postComment: { text: "댓글", color: "blue" },
  postCommentReply: { text: "답글", color: "purple" },
};
const DATA = [
  { key: "index", val: "index", type: "text" },
  { key: "reporterIdentifier", val: " 신고자 아이디", type: "text" },
  { key: "reportedIdentifier", val: " 신고 대상 아이디", type: "text" },
  { key: "reason", val: " 신고 사유", type: "text" },
];

const ReportModal = ({ row, actionToggle }: ReportModalProps) => {
  return (
    <Detailmodal title={`신고 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="신고타입" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={REPORT_TYPE[row.type].text} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="신고자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.reporterIdentifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="피신고자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.reportedIdentifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="사유" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.reason} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="생성일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="조치" />
        </div>
        <div className="flex-1">
          <button onClick={() => actionToggle(row.index, row.type)}>
            {row.isActed ? <CheckActive /> : <Check />}
          </button>
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="조치일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.actedAt)} />
        </div>
      </Flex>
    </Detailmodal>
  );
};
const Report = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [reportList, setReportList] = useState<ReportT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);
  const [seletedPost, setSeletedPost] = useState({} as PostOneT);
  const [seletedComment, setSeletedComment] = useState({} as CommentT);

  // 신고리스트
  const getReportList = async () => {
    const { key = "", val = "" } = location.state || {};
    const {
      data: { data },
    } = await reportApi.get(`${key}=${val}`);

    setMax(data.reportsCount);
    setReportList(data.reports);
  };
  // 신고된 포스트
  const getOnePost = async (idx: number) => {
    const { data } = await postsApi.getOne(idx);
    setSeletedPost(data.data.post);
  };
  // 신고된 댓글
  const getOneComment = async (idx: number) => {
    const { data } = await commentApi.getOne(idx);
    setSeletedComment(data.data.postComment);
  };
  // 신고된 답글
  const getOneReply = async (idx: number) => {
    const { data } = await commentApi.getReplyOne(idx);
    setSeletedComment(data.data.postCommentReply);
  };
  // 게시물 타입 클릭 (해당 포스트 or 댓글 모달 열림)
  const onClickType = (type: ReportTypeT, idx: number) => {
    switch (type) {
      case "post":
        getOnePost(idx);
        dispatch(modalToggle(2));
        break;
      case "postComment":
        getOneComment(idx);
        dispatch(modalToggle(3));
        break;
      case "postCommentReply":
        getOneReply(idx);
        dispatch(modalToggle(3));
        break;

      default:
        break;
    }
  };
  // 신고 조치
  const actionToggle = async (reportId: number, reportType: ReportTypeT) => {
    const confirm = window.confirm("조치상태를 변경 하시겠습니까?");
    if (!confirm) return;

    const { data } = await reportApi.putAction(reportId, reportType);
    data.code === "SUCCESS" && getReportList();
  };

  const columns: TableProps<ReportT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["ascend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(reportList, a, "index"),
    },
    {
      title: "신고타입",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (type, report) => (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClickType(report.type, report.id);
          }}
        >
          <Tag color={REPORT_TYPE[type].color}>{REPORT_TYPE[type].text}</Tag>
        </button>
      ),
    },
    {
      title: "신고자",
      dataIndex: "reporterIdentifier",
      key: "reporterIdentifier",
      sorter: (a, b) =>
        a.reporterIdentifier.localeCompare(b.reporterIdentifier),
    },
    {
      title: "피신고자",
      dataIndex: "reportedIdentifier",
      key: "reportedIdentifier",
      sorter: (a, b) =>
        a.reportedIdentifier.localeCompare(b.reportedIdentifier),
    },
    {
      title: "사유",
      dataIndex: "reason",
      key: "reason",
      sorter: (a, b) => a.reason.localeCompare(b.reason),
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 135,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => dateFormat(date),
    },
    {
      title: "조치",
      dataIndex: "isActed",
      key: "isActed",
      width: 100,
      sorter: (a, b) => {
        return a.isActed.toString().localeCompare(b.isActed.toString());
      },
      render: (isActed, report) => (
        <button onClick={() => actionToggle(report.index, report.type)}>
          {isActed ? <CheckActive /> : <Check />}
        </button>
      ),
    },
    {
      title: "조치일자",
      dataIndex: "actedAt",
      key: "actedAt",
      width: 135,
      sorter: (a, b) => (a.actedAt || "").localeCompare(b.actedAt || ""),
      render: (date) => dateFormat(date),
    },
  ];

  useEffect(() => {
    getReportList();
  }, [location]);

  return (
    <div>
      <Flex x="justify-between">
        <HeadLine text="신고 리포트" extraBold />
        <button onClick={() => dispatch(modalToggle(100))}>
          <Search />
        </button>
      </Flex>
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <Title text={`${max}개의 신고`} />
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={reportList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                const target: any = e.target;
                const value: any = target.tagName;
                setSelectedRowIdx(record.index);
                const isAction =
                  value.includes("rect") || value.includes("path"); // 스위치 클릭 여부
                !isAction && dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <ReportModal
          row={
            reportList.find((el) => el.index === selectedRowIdx) ||
            ({} as ReportT)
          }
          actionToggle={actionToggle}
        />
      )}
      <SearchModal data={DATA} />
      <PostModal
        row={seletedPost || ({} as PostOneT)}
        activeToggle={() =>
          activePostToggle(seletedPost.index, () => {
            getOnePost(seletedPost.index);
          })
        }
        modalNum={2}
      />
      <CommnetModal
        row={seletedComment}
        activeToggle={() =>
          activeCommentToggle(seletedComment.index, seletedComment.type, () => {
            seletedComment.type === "comment"
              ? getOneComment(seletedComment.index)
              : getOneReply(seletedComment.index);
          })
        }
        modalNum={3}
      />
    </div>
  );
};

export default Report;
