import React, { useState, useEffect } from "react";

import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";

interface LoginTemplateProps {
  Title: JSX.Element;
  Desc: JSX.Element;
  Id: JSX.Element;
  Pw: JSX.Element;
  RePw: JSX.Element;
  Msg: JSX.Element;
  Btn: JSX.Element;
  login: React.FormEventHandler<HTMLFormElement>;
}

const LoginTemplate = ({
  Title,
  Desc,
  Id,
  Pw,
  RePw,
  Msg,
  Btn,
  login,
}: LoginTemplateProps) => {
  return (
    <div className="">
      <div className="bg-white w-[616px] mx-auto rounded-3xl p-50">
        <Flex full vertical>
          {Title}
          <Space y={15} />
          {Desc}
        </Flex>
        <Space y={25} />
        <form onSubmit={login} className="relative">
          {Id}
          <Space y={40} />
          {Pw}
          <Space y={40} />
          {RePw}
          <Space y={40} />
          {Msg}
          <Space y={64} />
          {Btn}
        </form>
      </div>
    </div>
  );
};

export default LoginTemplate;
