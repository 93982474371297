import { listApi } from "Apis/apis";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Caption from "Components/Atoms/Text/caption";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { dateFormat, getIdx } from "Util/function";
import { Table } from "antd";
import type { TableProps } from "antd";
import { ReactComponent as Search } from "Util/Svgs/search.svg";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ListT } from "types/interface";
import SearchModal from "Components/Molecules/Modal/searchModal";

interface ListModalProps {
  row: ListT;
}

const DATA = [
  { key: "index", val: "index", type: "text" },
  { key: "identifier", val: "ID", type: "text" },
  { key: "nickname", val: "닉네임", type: "text" },
  { key: "listTitle", val: "리스트제목", type: "text" },
  { key: "createdAt", val: "생성일", type: "date" },
];

const CommnetModal = ({ row }: ListModalProps) => {
  return (
    <Detailmodal title={`리스트 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="ID" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.identifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="닉네임" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.nickname} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="리스트 이름" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.title} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="리스트 설명" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.content} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="비공개 여부" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.isPrivate ? "O" : "X"} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="생성일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="구독자 수" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.subscribersCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="삭제일자" />
        </div>
        <div className="flex-1">
          <Caption
            color="text-headline"
            text={row.deletedAt ? dateFormat(row.deletedAt) : "-"}
          />
        </div>
      </Flex>
    </Detailmodal>
  );
};
const List = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [listList, setLIstList] = useState<ListT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const getListList = async () => {
    const { key = "", val = "" } = location.state || {};
    const {
      data: { data },
    } = await listApi.get(`${key}=${val}`);

    setMax(data.listsCount);
    setLIstList(data.lists);
  };

  const columns: TableProps<ListT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["ascend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(listList, a, "index"),
    },
    {
      title: "ID",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
    },
    {
      title: "닉네임",
      dataIndex: "nickname",
      width: 100,
      key: "nickname",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
    },
    {
      title: "리스트 이름",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "리스트 설명",
      dataIndex: "content",
      key: "content",
      sorter: (a, b) => a.content.localeCompare(b.content),
    },
    {
      title: "비공개 여부",
      dataIndex: "isPrivate",
      key: "isPrivate",
      render: (type) => (type ? "O" : "X"),
      sorter: (a, b) => {
        return a.isPrivate.toString().localeCompare(b.isPrivate.toString());
      },
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 135,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => dateFormat(date),
    },
    {
      title: "구독자 수",
      dataIndex: "subscribersCount",
      key: "subscribersCount",
      width: 135,
      sorter: (a, b) => a.subscribersCount - b.subscribersCount,
    },
    {
      title: "삭제일자",
      dataIndex: "deletedAt",
      key: "deletedAt",
      width: 135,
      sorter: (a, b) => (a.deletedAt || "").localeCompare(b.deletedAt || ""),
      render: (date) => dateFormat(date) || "",
    },
  ];

  useEffect(() => {
    getListList();
  }, [location]);

  return (
    <div>
      <Flex x="justify-between">
        <HeadLine text="리스트관리" extraBold />
        <button onClick={() => dispatch(modalToggle(100))}>
          <Search />
        </button>
      </Flex>
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <Title text={`${max}개의 리스트`} />
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={listList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                setSelectedRowIdx(record.index);
                dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <CommnetModal
          row={
            listList.find((el) => el.index === selectedRowIdx) || ({} as ListT)
          }
        />
      )}
      <SearchModal data={DATA} />
    </div>
  );
};

export default List;
