import React, { useState } from "react";
import { useAppDispatch } from "Store/store";
import HeadLine from "Components/Atoms/Text/headLine";
import useInput from "Util/Hooks/useInput";
import Button from "Components/Atoms/Button/button";
import InputWithLabel from "Components/Molecules/InputWithLabel/inputWithLabel";
import ModifyModalTemplate from "./modifyModalTemplate";
import { accountApi, pointApi } from "Apis/apis";
import { AccountT, PostPointReqBodyT } from "types/interface";
import { modalToggle } from "Store/Slices/modal";
import AutoCom from "Components/Molecules/AutoCom/autoCom";

const ModifyModal = ({ getPointList }: { getPointList: () => {} }) => {
  const dispatch = useAppDispatch();

  const { setValue: setId, value: id, onChange } = useInput("");
  const { setValue: setPw, ...restPw } = useInput("");
  const { setValue: setPoint, ...restPoint } = useInput("");

  const [msg, setMsg] = useState("");
  const [userList, setUserList] = useState<AccountT[]>([]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const confirm = window.confirm("포인트를 지급 / 차감 하시겠습니까?");
    if (!confirm) return;
    const body: PostPointReqBodyT = {
      identifier: id,
      usage: restPw.value,
      amount: +restPoint.value,
    };

    try {
      const { data } = await pointApi.post(body);
      if (data.code === "SUCCESS") {
        setId("");
        setPw("");
        setPoint("");
        dispatch(modalToggle(0));
        getPointList();
      }
    } catch (error) {
      const err: any = error;
      setMsg(err.response.data.message);
    }
  };

  const onTyping: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    onChange(e);
    const val = e.target.value;
    const { data } = await accountApi.get(`identifier=${val}`);
    if (val === "") {
      setUserList([]);
      return;
    }
    setUserList(data.data.users);
  };
  const onClickAutoCom = (nick: string) => {
    setId(nick);
    setUserList([]);
  };

  return (
    <ModifyModalTemplate
      Title={<HeadLine extraBold text="포인트 지급 / 차감" />}
      Id={
        <InputWithLabel
          text="유저ID"
          placeholder="clpick@gmail.com"
          value={id}
          onChange={onTyping}
        />
      }
      AutoCom={<AutoCom onClick={onClickAutoCom} userList={userList} />}
      Content={
        <InputWithLabel text="세부내용" placeholder="세부내용" {...restPw} />
      }
      Point={
        <InputWithLabel
          text="포인트 지급(+)/차감(-) "
          placeholder="500"
          type="number"
          {...restPoint}
        />
      }
      Msg={
        <p className="absolute left-0 top-380 text-warning text-18 semiBold">
          {msg}
        </p>
      }
      Btn={
        <div className="text-center px-50">
          <Button type="submit" text="확인" />
        </div>
      }
      onSubmit={onSubmit}
    />
  );
};

export default ModifyModal;
