import React, { useState, useEffect } from "react";

import Space from "Components/Atoms/Space/space";
import Plainmodal from "Components/Molecules/Modal/plainmodal";
import HeadLine from "Components/Atoms/Text/headLine";
import { GoodsT } from "types/interface";
import Button from "Components/Atoms/Button/button";
import { goodsApi } from "Apis/apis";
import { useAppDispatch } from "Store/store";
import { modalToggle } from "Store/Slices/modal";

interface modifyModalTemplateProps {
  row: GoodsT;
  getGoods: () => {};
}

const NoticeModal = ({ row, getGoods }: modifyModalTemplateProps) => {
  const dispatch = useAppDispatch();

  const [notice, setNotice] = useState("");

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const { data } = await goodsApi.putNotice({
      index: row.index,
      notice,
    });
    if (data.code === "SUCCESS") {
      getGoods();
      alert("수정되었습니다.");
      dispatch(modalToggle(0));
    }
  };

  useEffect(() => {
    setNotice(row.notice || "");
  }, [row]);

  return (
    <Plainmodal modalNum={2}>
      <HeadLine extraBold text="상품유의사항" />
      <Space y={24} />
      <form onSubmit={onSubmit}>
        <textarea
          className="w-full p-16 rounded-lg resize-none h-500 border-1 border-primary"
          onChange={(e) => setNotice(e.target.value)}
          value={notice}
        ></textarea>
        <Space y={24} />
        <Button type="submit" text="확인" />
      </form>
    </Plainmodal>
  );
};

export default NoticeModal;
