import { topicApi } from "Apis/apis";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Caption from "Components/Atoms/Text/caption";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { comma, dateFormat, getIdx } from "Util/function";
import { Table, Switch } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { TopicT } from "types/interface";
import { ReactComponent as Search } from "Util/Svgs/search.svg";
import { useLocation } from "react-router-dom";
import SearchModal from "Components/Molecules/Modal/searchModal";

interface TopicModalProps {
  row: TopicT;
  activeToggle: (idx: number) => {};
}

const TOPIC_TYPE: { [idx: string]: string } = {
  fixedTerm: "기간토픽",
  normal: "일반토픽",
};

const DATA = [
  { key: "index", val: "index", type: "text" },
  { key: "identifier", val: "ID", type: "text" },
  { key: "nickname", val: "닉네임", type: "text" },
  { key: "title", val: "제목", type: "text" },
  { key: "content", val: "내용", type: "text" },
  { key: "createdAt", val: "생성일", type: "date" },
];

const TopciModal = ({ row, activeToggle }: TopicModalProps) => {
  return (
    <Detailmodal title={`토픽 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="ID" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.identifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="닉네임" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.nickname} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="제목" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.title} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="설명" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.content} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="리워즈" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.rewardAmount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="토픽타임" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={TOPIC_TYPE[row.type]} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="참여자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.participantsCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="생성일" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="최근참여" />
        </div>
        <div className="flex-1">
          <Caption
            color="text-headline"
            text={dateFormat(row.lastParticipatedAt)}
          />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="삭제일자" />
        </div>
        <div className="flex-1">
          <Caption
            color="text-headline"
            text={row.deletedAt ? dateFormat(row.deletedAt) : "-"}
          />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="숨김" />
        </div>
        <Switch
          onChange={() => {
            activeToggle(row.index);
          }}
          checked={row.isHidden}
        />
      </Flex>
    </Detailmodal>
  );
};

const Topic = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [topicList, setTopicList] = useState<TopicT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const getTopicList = async () => {
    const { key = "", val = "" } = location.state || {};

    const {
      data: { data },
    } = await topicApi.get(`${key}=${val}`);
    setMax(data.topicsCount);
    setTopicList(data.topics);
  };

  const activeToggle = async (topicId: number) => {
    const confirm = window.confirm("숨김상태를 변경 하시겠습니까?");
    if (!confirm) return;

    const { data } = await topicApi.putHidden(topicId);
    data.code === "SUCCESS" && getTopicList();
  };

  const columns: TableProps<TopicT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["ascend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(topicList, a, "index"),
    },
    {
      title: "ID",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
    },
    {
      title: "닉네임",
      dataIndex: "nickname",
      width: 100,
      key: "nickname",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
    },
    {
      title: "토픽제목",
      dataIndex: "title",
      key: "title",
      width: 150,
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "세부설명",
      dataIndex: "content",
      key: "content",
      sorter: (a, b) => a.content.localeCompare(b.content),
      width: 500,
      render: (reward: string) => <div className="ells"> {reward}</div>,
    },
    {
      title: "리워즈",
      dataIndex: "rewardAmount",
      key: "rewardAmount",
      sorter: (a, b) => a.rewardAmount - b.rewardAmount,
      width: 100,
      render: (reward) => comma(reward),
    },
    {
      title: "토픽타입",
      dataIndex: "type",
      key: "type",
      width: 100,
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (type) => TOPIC_TYPE[type],
    },
    {
      title: "참여자 수",
      dataIndex: "participantsCount",
      key: "participantsCount",
      sorter: (a, b) => a.participantsCount - b.participantsCount,
      width: 110,
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 130,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => dateFormat(date),
    },
    {
      title: "최근참여",
      dataIndex: "lastParticipatedAt",
      key: "lastParticipatedAt",
      width: 135,
      sorter: (a, b) =>
        a.lastParticipatedAt.localeCompare(b.lastParticipatedAt),

      render: (date) => dateFormat(date),
    },
    {
      title: "삭제일자",
      dataIndex: "deletedAt",
      key: "deletedAt",
      width: 135,
      sorter: (a, b) => (a.deletedAt || "").localeCompare(b.deletedAt || ""),
      render: (date) => dateFormat(date) || "",
    },
    {
      title: "숨김",
      dataIndex: "isHidden",
      key: "isHidden",
      width: 100,
      sorter: (a, b) => {
        return a.isHidden.toString().localeCompare(b.isHidden.toString());
      },
      render: (hidden, topic) => (
        <Switch onChange={() => activeToggle(topic.index)} checked={hidden} />
      ),
    },
  ];

  useEffect(() => {
    getTopicList();
  }, [location]);

  return (
    <div>
      <Flex x="justify-between">
        <HeadLine text="토픽관리" extraBold />
        <button onClick={() => dispatch(modalToggle(100))}>
          <Search />
        </button>
      </Flex>
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <Title text={`${max}개의 토픽`} />
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={topicList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                const target: any = e.target;
                setSelectedRowIdx(record.index);
                const isSwitch = target.classList.value.includes("switch"); // 스위치 클릭 여부
                !isSwitch && dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <TopciModal
          row={
            topicList.find((el) => el.index === selectedRowIdx) ||
            ({} as TopicT)
          }
          activeToggle={activeToggle}
        />
      )}
      <SearchModal data={DATA} />
    </div>
  );
};

export default Topic;
