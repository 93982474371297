import Space from "Components/Atoms/Space/space";
import Caption from "Components/Atoms/Text/caption";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { DatePicker } from "antd";
import { useEffect, useRef, useState } from "react";
import Input from "Components/Atoms/Input/input";
import Button from "Components/Atoms/Button/button";
import useInput from "Util/Hooks/useInput";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const DATE_FORMAT = "YYYYMMDD";

const SearchModal = ({
  data,
}: {
  data: { key: string; val: string; type: string }[];
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const modalNum = useSelector((state: ReducerType) => state.modal);
  const inputRef = useRef<HTMLInputElement>(null);

  const [key, setKey] = useState(data[0].key);
  const { setValue: setVal, ...restVal } = useInput("");
  const [type, setType] = useState(data[0].type);

  const onChangeDate = (date: dayjs.Dayjs, dates: string | string[]) => {
    if (typeof dates !== "string") return;
    setVal(dates);
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    navigate("", {
      state: {
        key,
        val: restVal.value,
      },
    });
    dispatch(modalToggle(0));
  };

  useEffect(() => {
    setVal("");
  }, [type]);

  useEffect(() => {
    modalNum === 100 && inputRef.current?.focus();
  }, [modalNum]);

  return (
    <Detailmodal title={`검색`} modalNum={100}>
      {data.map((el) => {
        return (
          <button
            key={el.key}
            onClick={() => {
              setKey(el.key);
              setType(el.type);
              setTimeout(() => {
                inputRef.current?.focus();
              }, 0);
            }}
            className={`border-1  rounded-3xl px-35 py-8 mr-16 mb-16 ${
              key === el.key
                ? "border-primary bg-primary text-white"
                : "border-[#979797]"
            }`}
          >
            {el.val}
          </button>
        );
      })}
      <Space y={16} />
      <Caption text="검색어" color="text-body" extraBold />
      <Space y={16} />
      <form onSubmit={onSubmit}>
        {type === "text" ? (
          <Input
            inputRef={inputRef}
            {...restVal}
            placeholder="검색어를 입력 해주세요"
          />
        ) : (
          <div className="h-56">
            <DatePicker
              size="large"
              value={
                restVal.value
                  ? dayjs(restVal.value, DATE_FORMAT)
                  : dayjs(dayjs().format(DATE_FORMAT))
              }
              format={DATE_FORMAT}
              onChange={onChangeDate}
            />
          </div>
        )}
        <Space y={123} />
        <Button text="검색하기" type="submit" />
      </form>
    </Detailmodal>
  );
};

export default SearchModal;
