import { AccountT } from "types/interface";

interface AutoComProps {
  userList: AccountT[];
  onClick: (nick: string) => void;
}

const AutoCom = ({ userList, onClick }: AutoComProps) => {
  return (
    <div className="absolute flex flex-col w-full">
      {userList.map((el) => (
        <button
          type="button"
          onClick={() => onClick(el.identifier)}
          className="flex items-center w-full gap-8 px-16 py-8 text-white bg-caption hover:bg-headline"
        >
          <img src={el.profileImage} className="w-40 rounded-full" alt="" />
          <span>{el.identifier}</span>
        </button>
      ))}
    </div>
  );
};

export default AutoCom;
